(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:ComfortCardItemDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('ComfortCardItemDialogCtrl', ComfortCardItemDialogCtrl);

  function ComfortCardItemDialogCtrl($mdDialog, FormControl, CardType, cardTypeMap, countries, _, $q, $rootScope, user, Cart, orderType, $scope, moment) {
    var vm = this
      , formControl = new FormControl()
      , deferred = $q.defer()
      , cart = new Cart()
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType';

    cart.id = orderType;
    vm.itemDialogForm = {};
    vm.countries = countries;
    vm.isNewItem = _.isEmpty(vm.item);
    vm.selectedProductType = localStorage.getItem(EdenredECSselectedProductType);

    CardType.query().$promise
      .then(function (data) {
        vm.cardTypeId = _.result(_.find(data, { 'category': cardTypeMap[vm.selectedProductType] }), 'id');
      });


    vm.isNewCustomer = function isNewCustomer() {
      return _.isEmpty(user);
    };

    vm.isOrderNewCardType = function isOrderNewCardType() {
      return vm.orderType === '1';
    };

    if (vm.isOrderNewCardType()) {
      formControl.setSuccessText('order.unique.toast.save');
    } else {
      formControl.setSuccessText('order.unique.toast.edit');
    }

    vm.closeDialog = function closeDialog() {
      $mdDialog.hide();
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      deferred = $q.defer();

      formControl
        .process(deferred.promise)
        .then(function () {
          $rootScope.$broadcast('itemList.refresh');
          vm.closeDialog();
        });

      if (vm.isNewItem) {
        vm.item.cardTypeId = vm.cardTypeId;
        vm.item.$save(deferred.resolve, deferred.reject);
      } else {
        vm.item.$update(deferred.resolve, deferred.reject);
      }
    };
  }
}());
